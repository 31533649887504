import {PropTypes} from "prop-types";
import {observer} from "mobx-react";
import {cn} from "@/utils/cn";

const QuantitySelector = observer(
  ({value, onMinus, onPlus, onValueChange, disabled, className}) => {
    const quantity = value;
    const handleOnMinus = () => {
      onValueChange(quantity - 1);
      onMinus && onMinus(quantity - 1);
    };
    const handleOnPlus = () => {
      onValueChange(quantity + 1);
      onPlus && onPlus(quantity + 1);
    };

    return (
      <label
        className={cn(
          "flex h-[40px] w-fit min-w-[113px] items-center justify-between rounded-full border border-green",
          className,
        )}
      >
        <button
          type="button"
          className="mt-px pl-4 disabled:opacity-70"
          onClick={handleOnMinus}
          disabled={disabled}
          aria-label={`Reduce quantity by 1`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="2"
            viewBox="0 0 18 2"
            fill="none"
          >
            <path d="M0 1H18" stroke="#104234" strokeWidth="1.5" />
          </svg>
        </button>
        <span className="text-sm font-normal not-italic leading-[140%] tracking-[0.00875rem] text-dark-green md:text-base md:leading-[140%] md:tracking-[0.01rem]">
          {quantity}
        </span>
        <button
          type="button"
          className="pr-4 disabled:opacity-70"
          onClick={handleOnPlus}
          disabled={disabled}
          aria-label={`Increase quantity by 1`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path d="M8.82 18V0M0 8.82H18" stroke="#104234" strokeWidth="1.5" />
          </svg>
        </button>
      </label>
    );
  },
);

QuantitySelector.propTypes = {
  value: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onPlus: PropTypes.func,
  onMinus: PropTypes.func,
};

export default QuantitySelector;
